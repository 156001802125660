// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-404-tsx": () => import("./../../../src/pages/404/404.tsx" /* webpackChunkName: "component---src-pages-404-404-tsx" */),
  "component---src-pages-404-index-ts": () => import("./../../../src/pages/404/index.ts" /* webpackChunkName: "component---src-pages-404-index-ts" */),
  "component---src-templates-about-page-about-page-tsx": () => import("./../../../src/templates/AboutPage/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-about-page-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx": () => import("./../../../src/templates/BlogPost/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx" */),
  "component---src-templates-case-page-case-page-tsx": () => import("./../../../src/templates/CasePage/CasePage.tsx" /* webpackChunkName: "component---src-templates-case-page-case-page-tsx" */),
  "component---src-templates-employee-employee-tsx": () => import("./../../../src/templates/Employee/Employee.tsx" /* webpackChunkName: "component---src-templates-employee-employee-tsx" */),
  "component---src-templates-job-role-page-job-role-page-tsx": () => import("./../../../src/templates/JobRolePage/JobRolePage.tsx" /* webpackChunkName: "component---src-templates-job-role-page-job-role-page-tsx" */),
  "component---src-templates-join-us-page-join-us-page-tsx": () => import("./../../../src/templates/JoinUsPage/JoinUsPage.tsx" /* webpackChunkName: "component---src-templates-join-us-page-join-us-page-tsx" */),
  "component---src-templates-our-work-page-our-work-page-tsx": () => import("./../../../src/templates/OurWorkPage/OurWorkPage.tsx" /* webpackChunkName: "component---src-templates-our-work-page-our-work-page-tsx" */),
  "component---src-templates-page-blog-listing-page-blog-listing-tsx": () => import("./../../../src/templates/PageBlogListing/PageBlogListing.tsx" /* webpackChunkName: "component---src-templates-page-blog-listing-page-blog-listing-tsx" */),
  "component---src-templates-services-page-services-page-tsx": () => import("./../../../src/templates/ServicesPage/ServicesPage.tsx" /* webpackChunkName: "component---src-templates-services-page-services-page-tsx" */),
  "component---src-templates-standard-page-standard-page-tsx": () => import("./../../../src/templates/StandardPage/StandardPage.tsx" /* webpackChunkName: "component---src-templates-standard-page-standard-page-tsx" */),
  "component---src-templates-start-page-start-page-tsx": () => import("./../../../src/templates/StartPage/StartPage.tsx" /* webpackChunkName: "component---src-templates-start-page-start-page-tsx" */)
}

